import { Box, Grid, Typography, Divider } from "@mui/material";

import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { axiosInstance } from "src/network/adapter";
import { ApiEndpoints } from "src/network/endpoints";
import { toastError } from "src/utils/utils";

const ITSolutionDropDown = () => {
  // const [itSolutions, setItSolutions] = useState([]);
  // const [subItSolutions, setSubItSolutions] = useState([]);
  // const [subItSolutions1, setSubItSolutions1] = useState([]);
  // const [subItSolutions2, setSubItSolutions2] = useState([]);
  // const [subItSolutions3, setSubItSolutions3] = useState([]);
  // const navigate = useNavigate();
  // const fetchITSoltionsData = () => {
  //   axiosInstance
  //     .get(ApiEndpoints.IT_SOLUTIONS.list)
  //     .then((response) => {
  //       setItSolutions(response.data.data);
  //     })
  //     .catch((error) => {
  //       toastError(error);
  //     });
  // };

  // const fetchSubITSoltionsData = (id) => {
  //   axiosInstance
  //     .get(ApiEndpoints.SUB_IT_SOLUTIONS.list(id))
  //     .then((response) => {
  //       setSubItSolutions(response.data.data);
  //       const responseData = response.data.data;
  //       if (responseData) {
  //         responseData?.map((item, index) => {
  //           fetchSubITSoltionsList(item.id, index);
  //         });
  //       }
  //     })
  //     .catch((error) => {
  //       toastError(error);
  //     });
  // };

  // const fetchSubITSoltionsList = (id, index) => {
  //   axiosInstance
  //     .get(ApiEndpoints.SUB_IT_SOLUTIONS_LIST.list(id))
  //     .then((response) => {
  //       switch (index) {
  //         case 0:
  //           setSubItSolutions1(response.data.data);
  //           break;
  //         case 1:
  //           setSubItSolutions2(response.data.data);
  //           break;
  //         case 2:
  //           setSubItSolutions3(response.data.data);
  //       }
  //     })
  //     .catch((error) => {
  //       toastError(error);
  //     });
  // };

  // useEffect(() => {
  //   fetchITSoltionsData();
  //   fetchSubITSoltionsData(13);
  // }, []);
  // const handleITSolutionClick = (id, event) => {
  //   event.stopPropagation();
  //   fetchSubITSoltionsData(id);
  // };

  // const handleSubItSolution1Click = (index, subITSolutionId) => {
  //   if (subITSolutionId) {
  //     localStorage.setItem("ITSolutionId", subITSolutionId);
  //   }
  //   navigate(`/it-solution-overview/${subITSolutionId}`);
  // };
  // const handleSubItSolution2Click = (index, subITSolutionId) => {
  //   if (subITSolutionId) {
  //     localStorage.setItem("ITSolutionId", subITSolutionId);
  //   }
  //   navigate(`/it-solution-overview/${subITSolutionId}`);
  // };
  // const handleSubItSolution3Click = (index, subITSolutionId) => {
  //   if (subITSolutionId) {
  //     localStorage.setItem("ITSolutionId", subITSolutionId);
  //   }
  //   navigate(`/it-solution-overview/${subITSolutionId}`);
  // };
  // const handleSubItSolutionClick = (index, subITSolutionId) => {
  //   if (subITSolutionId) {
  //     localStorage.setItem("ITSolutionId", subITSolutionId);
  //   }
  //   navigate(`/it-solution-overview/${subITSolutionId}`);
  // };
  const [itSolutions, setItSolutions] = useState([]);
  const [subItSolutions, setSubItSolutions] = useState([]);
  const [subItSolutionsLists, setSubItSolutionsLists] = useState([]); // Store multiple sub-lists dynamically
  const navigate = useNavigate();

  const fetchITSoltionsData = () => {
    axiosInstance
      .get(ApiEndpoints.IT_SOLUTIONS.list)
      .then((response) => {
        const responseData = response.data.data;
        setItSolutions(response.data.data);

        if (responseData.length > 0) {
          fetchSubITSoltionsData(responseData[0].id); // Get the id of the first IT solution
        }
      })
      .catch((error) => {
        toastError(error);
      });
  };

  const fetchSubITSoltionsData = (id) => {
    axiosInstance
      .get(ApiEndpoints.SUB_IT_SOLUTIONS.list(id))
      .then((response) => {
        setSubItSolutions(response.data.data);
        const responseData = response.data.data;
        if (responseData) {
          responseData.forEach((item, index) => {
            fetchSubITSoltionsList(item.id, index);
          });
        }
      })
      .catch((error) => {
        toastError(error);
      });
  };

  const fetchSubITSoltionsList = (id, index) => {
    axiosInstance
      .get(ApiEndpoints.SUB_IT_SOLUTIONS_LIST.list(id))
      .then((response) => {
        setSubItSolutionsLists((prevState) => {
          const newState = [...prevState];
          newState[index] = response.data.data; // Store each sub-list at its respective index
          return newState;
        });
      })
      .catch((error) => {
        toastError(error);
      });
  };

  // On component mount, fetch the main IT solutions and sub IT solutions for default ID 13
  useEffect(() => {
    fetchITSoltionsData();
  }, []);

  // Function to handle main IT solution clicks and fetch its sub IT solutions
  const handleITSolutionClick = (id, event) => {
    event.stopPropagation();
    fetchSubITSoltionsData(id);
  };

  // Reusable function to handle clicks on sub IT solutions and navigate to the detail page
  const handleSubItSolutionClick = (index, subITSolutionId) => {
    if (subITSolutionId) {
      localStorage.setItem("ITSolutionId", subITSolutionId);
    }
    navigate(`/it-solution-overview/${subITSolutionId}`);
  };

  return (
    <>
      <Box sx={{ width: "1280px", px: 10, py: 5, maxHeight: "756px" }}>
        <Grid container>
          <Grid
            item
            xs={4}
            sx={{ display: "flex", flexDirection: "column", gap: 5 }}
          >
            <Typography
              sx={{
                fontSize: { lg: "24px" },
                lineHeight: { lg: "32px" },
                fontWeight: 600,
                color: "#602DE1",
              }}
            >
              IT Solutions
            </Typography>
            <Box
              sx={{ pl: 5, display: "flex", flexDirection: "column", gap: 7 }}
            >
              {itSolutions?.map((item, index) => (
                <Typography
                  onClick={(event) => handleITSolutionClick(item.id, event)}
                  key={index}
                  sx={{
                    fontSize: { lg: "16px" },
                    lineHeight: { lg: "24px" },
                    fontWeight: 600,
                    color: "#101828",
                    "&:hover": {
                      color: "#602DE1",
                    },
                    cursor: "pointer",
                  }}
                >
                  {item.title}
                </Typography>
              ))}
            </Box>
          </Grid>
          {/* <Grid item container xs={8}>
            <Grid item container xs={12} columnSpacing={8}>
              {subItSolutions?.map((item, index) => (
                <Grid item xs={4} key={index}>
                  <Typography
                    sx={{
                      fontSize: { lg: "16px" },
                      lineHeight: { lg: "24px" },
                      fontWeight: 600,
                      color: "#602DE1",
                      textWrap: "wrap",
                      mb: 1,
                    }}
                  >
                    {item.title}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: { lg: "14px" },
                      lineHeight: { lg: "20px" },
                      fontWeight: 400,
                      color: "#667085",
                      textWrap: "wrap",
                    }}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: `<style>p { margin: 0; }</style>${item.description}`,
                      }}
                    />
                  </Typography>
                </Grid>
              ))}
            </Grid>
            <Grid item xs={12} sx={{ py: 5, color: "#CACACA" }}>
              <Divider />
            </Grid>
            <Grid item container xs={12} columnSpacing={8}>
              <Grid
                item
                xs={4}
                sx={{ display: "flex", flexDirection: "column", gap: 10 }}
              >
                {subItSolutions1?.map((item, index) => (
                  <Box
                    key={index}
                    onClick={() => handleSubItSolution1Click(index, item.id)}
                  >
                    <Typography
                      sx={{
                        fontSize: { lg: "16px" },
                        lineHeight: { lg: "24px" },
                        fontWeight: 500,
                        color: "#101828",
                        textWrap: "wrap",
                        "&:hover": {
                          color: "#602DE1",
                        },
                        cursor: "pointer",
                      }}
                      gutterBottom
                    >
                      {item.title}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: { lg: "14px" },
                        lineHeight: { lg: "20px" },
                        fontWeight: 400,
                        color: "#667085",
                        textWrap: "wrap",
                        cursor: "pointer",
                      }}
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: `<style>p { margin: 0; }</style>${item.description}`,
                        }}
                      />
                    </Typography>
                  </Box>
                ))}
              </Grid>
              <Grid
                item
                xs={4}
                sx={{ display: "flex", flexDirection: "column", gap: 10 }}
              >
                {subItSolutions2?.map((item, index) => (
                  <Box
                    key={index}
                    onClick={() => handleSubItSolution2Click(index, item.id)}
                  >
                    <Typography
                      sx={{
                        fontSize: { lg: "16px" },
                        lineHeight: { lg: "24px" },
                        fontWeight: 500,
                        color: "#101828",
                        textWrap: "wrap",
                        "&:hover": {
                          color: "#602DE1",
                        },
                        cursor: "pointer",
                      }}
                      gutterBottom
                    >
                      {item.title}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: { lg: "14px" },
                        lineHeight: { lg: "20px" },
                        fontWeight: 400,
                        color: "#667085",
                        textWrap: "wrap",
                        cursor: "pointer",
                      }}
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: `<style>p { margin: 0; }</style>${item.description}`,
                        }}
                      />
                    </Typography>
                  </Box>
                ))}
              </Grid>
              <Grid
                item
                xs={4}
                sx={{ display: "flex", flexDirection: "column", gap: 10 }}
              >
                {subItSolutions3?.map((item, index) => (
                  <Box
                    key={index}
                    onClick={() => handleSubItSolution3Click(index, item.id)}
                  >
                    <Typography
                      sx={{
                        fontSize: { lg: "16px" },
                        lineHeight: { lg: "24px" },
                        fontWeight: 500,
                        color: "#101828",
                        textWrap: "wrap",
                        "&:hover": {
                          color: "#602DE1",
                          cursor: "pointer",
                        },
                      }}
                      gutterBottom
                    >
                      {item.title}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: { lg: "14px" },
                        lineHeight: { lg: "20px" },
                        fontWeight: 400,
                        color: "#667085",
                        textWrap: "wrap",
                        cursor: "pointer",
                      }}
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: `<style>p { margin: 0; }</style>${item.description}`,
                        }}
                      />
                    </Typography>
                  </Box>
                ))}
              </Grid>
            </Grid>
          </Grid> */}
          <Grid item container xs={8} columnSpacing={10}>
            {/* <Grid container spacing={8}> */}
            {subItSolutions?.map((item, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Box sx={{ mb: 3 }}>
                  <Typography
                    sx={{
                      fontSize: { lg: "16px" },
                      lineHeight: { lg: "24px" },
                      fontWeight: 600,
                      color: "#602DE1",
                      mb: 1,
                      textWrap: "wrap",
                    }}
                  >
                    {item.title}
                  </Typography>

                  <Typography
                    sx={{
                      fontSize: { lg: "14px" },
                      lineHeight: { lg: "20px" },
                      fontWeight: 400,
                      color: "#667085",
                      mb: 2,
                      textWrap: "wrap",
                    }}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: `<style>p { margin: 0; }</style>${item.description}`,
                      }}
                    />
                  </Typography>
                </Box>

                <Box sx={{ mb: 5 }}>
                  <Divider sx={{ width: "50%", borderWidth: "1px" }} />
                </Box>
                {/* Sub-data under the title */}
                {subItSolutionsLists[index]?.map((subItem, subIndex) => (
                  <Box
                    key={subIndex}
                    onClick={() =>
                      handleSubItSolutionClick(subIndex, subItem.id)
                    }
                    sx={{ mb: 10 }}
                  >
                    <Typography
                      sx={{
                        fontSize: { lg: "16px" },
                        lineHeight: { lg: "24px" },
                        fontWeight: 500,
                        color: "#101828",
                        textWrap: "wrap",
                        "&:hover": {
                          color: "#602DE1",
                          cursor: "pointer",
                        },
                        mb: 1,
                      }}
                    >
                      {subItem.title}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: { lg: "14px" },
                        lineHeight: { lg: "20px" },
                        fontWeight: 400,
                        color: "#667085",
                        textWrap: "wrap",
                      }}
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: `<style>p { margin: 0; }</style>${subItem.description}`,
                        }}
                      />
                    </Typography>
                  </Box>
                ))}
              </Grid>
            ))}
            {/* </Grid> */}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default ITSolutionDropDown;
