// ** Icon imports

// const subNavigation = () => {
//   const itSolutionID = localStorage.getItem("ITSolutionId");

//   return [
//     {
//       title: "Overview",
//       path: `/it-solution-overview/${itSolutionID}`,
//     },
//     {
//       title: "Features",
//       path: `/it-solution-features/${itSolutionID}`,
//     },
//     {
//       title: "Demo",
//       path: `/it-solution-demo/${itSolutionID}`,
//     },
//     {
//       title: "Resources",
//       path: `/it-solution-resources/${itSolutionID}`,
//     },
//     {
//       title: "Get Quote",
//       path: `/it-solution-getQuote/${itSolutionID}`,
//     },
//     {
//       title: "Support",
//       path: `/it-solution-supportUs/${itSolutionID}`,
//     },
//   ];
// };

const subNavigation = () => {
  return [
    {
      title: "Overview",
      path: `/it-solution-overview`,
    },
    {
      title: "Features",
      path: `/it-solution-features`,
    },
    {
      title: "Demo",
      path: `/it-solution-demo`,
    },
    {
      title: "Resources",
      path: `/it-solution-resources`,
    },
    {
      title: "Get Quote",
      path: `/it-solution-getQuote`,
    },
    {
      title: "Support",
      path: `/it-solution-supportUs`,
    },
  ];
};
export default subNavigation;
